import React from 'react'
import { Box, Typography, Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  section: { borderBottom: `1px dotted ${theme.palette.divider}` },
  subsection: { fontWeight: 'bold' },
  subsubsection: { fontWeight: 'bold' },
  caption: { fontWeight: 'bold' },
}))

type Props = { id?: string; className?: string }

export const Title: React.FC<Props> = ({ children, className, ...props }) => (
  <Typography
    {...props}
    variant="h4"
    component="h1"
    color="textPrimary"
    gutterBottom
    className={className}
  >
    {children}
  </Typography>
)

export const Chapter: React.FC<Props> = ({ children, className, ...props }) => (
  <Box mt={2} mb={1} className={className}>
    <Typography {...props} variant="h4" component="h2">
      {children}
    </Typography>
    <Divider />
  </Box>
)

export const Section: React.FC<Props> = ({ children, className, ...props }) => {
  const classes = useStyles()
  return (
    <Typography
      {...props}
      className={`${classes.section} ${className ? className : ''}`}
      variant="h5"
      component="h3"
      gutterBottom
    >
      {children}
    </Typography>
  )
}

export const SubSection: React.FC<Props> = ({
  children,
  className,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Typography
      {...props}
      variant="h6"
      component="h4"
      className={`${classes.subsection} ${className ? className : ''}`}
    >
      {children}
    </Typography>
  )
}

export const SubSubSection: React.FC<Props> = ({
  children,
  className,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Typography
      {...props}
      className={`${classes.subsubsection} ${className ? className : ''}`}
      variant="subtitle1"
      component="h5"
      gutterBottom
    >
      {children}
    </Typography>
  )
}

export const Caption: React.FC<Props> = ({ children, className, ...props }) => {
  const classes = useStyles()
  return (
    <Typography
      {...props}
      variant="caption"
      component="h6"
      gutterBottom
      className={`${classes.caption} ${className ? className : ''}`}
    >
      {children}
    </Typography>
  )
}

export const Paragraph: React.FC<Props> = ({
  children,
  className,
  ...props
}) => (
  <Typography
    {...props}
    variant="body1"
    component="p"
    gutterBottom
    className={className}
  >
    {children}
  </Typography>
)

export const Textarea: React.FC<{ text?: string }> = ({ text }) => (
  <Typography variant="body1" component="p" gutterBottom>
    {text &&
      text.split('\n').map((line, key) => (
        <React.Fragment key={key}>
          {line}
          <br />
        </React.Fragment>
      ))}
  </Typography>
)

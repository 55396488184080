import React from 'react'
import { Typography } from '@material-ui/core'

const Footer: React.FC = () => (
  <footer>
    <Typography variant="body2" align="center" color="textSecondary">
      Copyright © 男子校 All Rights Reserved.
    </Typography>
  </footer>
)

export default Footer

import React from 'react'

import Header, { HeaderProps } from './Header'
import { Container, Box, Divider, makeStyles } from '@material-ui/core'
import SEO, { SeoProps } from './SEO'
import Footer from './Footer'

const useStyles = makeStyles(theme => ({
  container: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
}))

const Layout: React.FC<SeoProps & HeaderProps> = ({
  children,
  path,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Container maxWidth="lg" className={classes.container}>
      <SEO {...props} />
      <Header path={path} />
      <main>
        <Box mb={8}>{children}</Box>
      </main>
      <Divider />
      <Footer />
    </Container>
  )
}
export default Layout

import React from 'react'
import { Link } from 'gatsby'
import { makeStyles, Grid, Button } from '@material-ui/core'
import HeaderLogo from '../../content/assets/header_logo.svg'

const useStyles = makeStyles(theme => {
  const border = `1px solid ${theme.palette.divider}`
  return {
    tab: {
      [theme.breakpoints.up('sm')]: { borderRight: border },
    },
    tabStart: {
      [theme.breakpoints.up('sm')]: { borderRight: border, borderLeft: border },
    },
    selected: { backgroundColor: theme.palette.grey[200] },
    headerLogo: { maxWidth: 600, marginBottom: theme.spacing(1) },
  }
})

const pages = [
  { label: 'Profile', href: '/profile/' },
  { label: 'Goods', href: '/goods/' },
  { label: 'Songs', href: '/songs/' },
  { label: 'Live', href: '/live/' },
  { label: 'Blog', href: '/blog/' },
  { label: 'Contact', href: '/contact/' },
] as const

export type HeaderProps = { path?: typeof pages[number]['label'] }

const Header: React.FC<HeaderProps> = ({ path }) => {
  const classes = useStyles()

  return (
    <header>
      <div>
        <Link to={`/`}>
          <HeaderLogo className={classes.headerLogo} />
        </Link>
      </div>
      <Grid container>
        {pages.map((page, i) => (
          <Grid
            item
            xs={4}
            sm
            key={page.href}
            className={`${i === 0 ? classes.tabStart : classes.tab} ${
              page.label === path ? classes.selected : ''
            }`}
          >
            <Button component={Link} to={page.href} fullWidth>
              {page.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </header>
  )
}

export default Header
